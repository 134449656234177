import { useParams, useSearchParams } from "react-router-dom";
import WorkflowLayout from "./WorkflowLayout";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { Heading, Table, Button, Dialog, IconButton } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";
import { toast } from "react-toastify";
import TextInput from "~/components/form/TextInput";
import { useState } from "react";
import { Trash, X } from "lucide-react";

interface ConversionItem {
	id: number;
	purchasable_id: string;
	purchasable_type: string;
}

interface Attribution {
	id: number;
	proceeds_amount_gross: string;
}

interface Conversion {
	id: string;
	customer_id: string | null;
	customer_email: string | null;
	customer_created_at: string | null;
	placement: string | null;
	variant_name: string;
	attribution: Attribution | null;
	items: ConversionItem[];
	completed_at: number | null;
}

interface ConversionResponse {
	current_page: number;
	data: Conversion[];
	first_page_url: string;
	from: number;
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
}

// Custom pagination component for this specific page
function RevenuePagination({ 
	data, 
	onPageChange, 
	currentPage 
}: { 
	data: ConversionResponse; 
	onPageChange: (page: number) => void;
	currentPage: number;
}) {
	return (
		<div className="flex justify-between items-center mt-4">
			<Button 
				onClick={() => onPageChange(currentPage - 1)} 
				disabled={!data.prev_page_url}
				variant="soft"
			>
				Previous
			</Button>
			<span className="text-gray-700">
				Showing {data.from} to {data.to}
			</span>
			<Button 
				onClick={() => onPageChange(currentPage + 1)} 
				disabled={!data.next_page_url}
				variant="soft"
			>
				Next
			</Button>
		</div>
	);
}

type EditConversionDialogProps = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	conversion: Conversion;
	onSuccess: () => void;
};

function EditConversionDialog({ 
	open, 
	onOpenChange, 
	conversion, 
	onSuccess 
}: EditConversionDialogProps) {
	const [amount, setAmount] = useState(conversion?.attribution?.proceeds_amount_gross || "");

	const handleEdit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		try {
			await apiClient.put(`/convert/attributions/${conversion.attribution?.id}`, {
				proceeds_amount_gross: amount,
			});
			onSuccess();
			onOpenChange(false);
		} catch (error) {
			console.error("Failed to update conversion:", error);
			toast.error("Failed to update conversion");
		}
	};

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content>
				<Dialog.Title>Edit Conversion Amount</Dialog.Title>
				<Form.Root onSubmit={handleEdit} >
					<div className="py-4">
						<TextInput
							name="proceeds_amount_gross"
							label="Proceeds Amount Gross"
							value={amount}
							onChange={(value) => setAmount(value)}
							type="number"
						/>
					</div>
					<div className="flex justify-end gap-3">
						<Button variant="soft" onClick={() => onOpenChange(false)}>
							Cancel
						</Button>
						<Button type="submit">
							Save Changes
						</Button>
					</div>
				</Form.Root>
			</Dialog.Content>
		</Dialog.Root>
	);
}

export default function WorkflowRevenuePage() {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPage = Number(searchParams.get("page")) || 1;

	const { data: workflow, error: workflowError } = useSWR(
		`/intel/flows/${params.id}`,
		getFetcher
	);

	const { data, error, mutate: mutateRevenue } = useSWR<ConversionResponse>(
		`/intel/flows/${params.id}/conversions?page=${currentPage}`,
		getFetcher
	);

	const [selectedConversion, setSelectedConversion] = useState<Conversion | null>(null);
	const [isEditing, setIsEditing] = useState(false);
	const [deletingId, setDeletingId] = useState<string | null>(null);
	const [confirmingDeleteId, setConfirmingDeleteId] = useState<string | null>(null);

	const handlePageChange = (page: number) => {
		setSearchParams({ page: page.toString() });
	};

	const handleDeleteClick = (e: React.MouseEvent, conversionId: string) => {
		e.stopPropagation();
		setConfirmingDeleteId(conversionId);
	};

	const handleConfirmDelete = async (e: React.MouseEvent, conversionId: string) => {
		e.stopPropagation();
		setDeletingId(conversionId);
		try {
			await apiClient.delete(`/intel/flows/${params.id}/activities/${conversionId}`);
			toast.success('Conversion deleted successfully');
			mutateRevenue();
		} catch (error) {
			toast.error('Failed to delete conversion');
		} finally {
			setDeletingId(null);
			setConfirmingDeleteId(null);
		}
	};

	const handleCancelDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		setConfirmingDeleteId(null);
	};

	if (workflowError) return <div>Failed to load</div>;
	if (!workflow) return <div>Loading...</div>;

	return (
		<WorkflowLayout workflow={workflow}>
			<div className="px-10 space-y-4">
				<Table.Root variant="surface">
					<Table.Header>
						<Table.Row>
							<Table.ColumnHeaderCell>Customer</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Placement</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Items</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Completed At</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell></Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{data?.data?.map((conversion) => (
							<Table.Row key={conversion.id}>
								<Table.Cell>
									<div>{conversion.customer_email || conversion.customer_id || 'N/A'}</div>
									<div className="text-gray-700 text-xs">{conversion.id}</div>
								</Table.Cell>
								<Table.Cell>{conversion.placement || 'N/A'}</Table.Cell>
								<Table.Cell>{conversion.variant_name}</Table.Cell>
								<Table.Cell>
									{conversion.items.length > 0 ? (
										<div className="space-y-1">
											{conversion.items.map((item) => (
												<div key={item.id} className="text-sm">
													{item.purchasable_type}: {item.purchasable_id}
												</div>
											))}
										</div>
									) : (
										'No items'
									)}
								</Table.Cell>
								<Table.Cell>
									{conversion.attribution?.proceeds_amount_gross 
										? `$${(parseInt(conversion.attribution.proceeds_amount_gross) / 100).toFixed(2)}` 
										: 'N/A'
									}
								</Table.Cell>
								<Table.Cell>
									{conversion.completed_at 
										? new Date(conversion.completed_at * 1000).toLocaleDateString() 
										: 'Not completed'
									}
								</Table.Cell>
								<Table.Cell>
									{conversion.attribution ? (
										<Button 
											size="1" 
											variant="soft" 
											onClick={() => {
												setSelectedConversion(conversion);
												setIsEditing(true);
											}}
										>
											Edit
										</Button>
									) : 'N/A'}
								</Table.Cell>
								<Table.Cell>
									{confirmingDeleteId === conversion.id ? (
										<div className="flex items-center gap-1 text-sm" onClick={e => e.stopPropagation()}>
											<IconButton 
												color="red" 
												variant="ghost" 
												size="1"
												disabled={deletingId === conversion.id}
												onClick={(e) => handleConfirmDelete(e, conversion.id)}
											>
												<Trash className="h-3 w-3" />
											</IconButton>
											<IconButton 
												variant="ghost" 
												size="1"
												onClick={handleCancelDelete}
											>
												<X className="h-3 w-3" />
											</IconButton>
										</div>
									) : (
										<IconButton 
											color="red" 
											variant="soft" 
											disabled={deletingId === conversion.id}
											onClick={(e) => handleDeleteClick(e, conversion.id)}
										>
											<Trash className="h-4 w-4" />
										</IconButton>
									)}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>

				{selectedConversion && (
					<EditConversionDialog
						open={isEditing}
						key={selectedConversion.id}
						onOpenChange={setIsEditing}
						conversion={selectedConversion}
						onSuccess={() => {
							mutateRevenue();
							toast.success('Conversion amount updated');
						}}
					/>
				)}

				{data && (
					<RevenuePagination
						data={data}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				)}
			</div>
		</WorkflowLayout>
	);
}